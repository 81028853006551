body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll !important;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  width: 4px;
  background: #e5e4e2;
  border-left: 0 solid #e5e4e2;
  border-right: 0 solid #e5e4e2;
}
::-webkit-scrollbar-thumb {
  /* background: #d43030; */
  background: rgba(255, 193, 7, 1);
  width: 0;
  height: 25%;
  transition: 0.5s ease;
  cursor: pointer;
}

/* TMP */

.contactForm
  .MuiOutlinedInput-input:not(:focus)
  + .MuiOutlinedInput-notchedOutline {
  border-color: #434445;
}


.programSheet {max-width: 100%}
.programSheet h4,
.programSheet p 
{margin: 0}
.programSheet .start, .programSheet .end {width: 72px;}

.programSheet .MuiCard-root:hover {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.programSheet .aprim {background-color: PaleGreen;}
.programSheet .acons {background-color: lightskyblue;}
.programSheet .prez {background-color: LightSalmon;}

.programSheet .MuiChip-root {border-radius: 4px;}

@media (max-width: 967px) {
  .programSheet .dayHead {
    display: block;
    margin-top: 64px;
  }
  .programSheet .start,
  .programSheet .end {
    display: inline-block;
    width: 50%;
    border: none;
    padding-bottom: 0;
  }
  .programSheet .slotActivities {
    display: block;
    width: 100%;
  }
}

.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -24px; /* gutter size offset */
  width: auto;
}
.masonry-grid_column {
  padding-left: 24px; /* gutter size */
  background-clip: padding-box;
}

.organizatori img {
  max-width: 100%;
}